import React from "react"
import styled from "@emotion/styled"
import {graphql} from "gatsby"
import Layout from "../components/Layout"
import PageTemplate from "@shockventures/gatsby-theme-common/src/templates/PageTemplate"
import siteContext from "../assets/siteContext"
import BlockContentSection from "@shockventures/gatsby-theme-common/src/sections/BlockContentSection";
import CoverSection from "../sections/CoverSection";
import ArtistsSection from "../sections/ArtistsSection";
import NewsSection from "@shockventures/gatsby-theme-common/src/sections/NewsSection";

export const query = graphql`    
    query ($id: String) {
        studio: sanityStudio(_id: {eq: $id}) {
            ...StudioFragment
        }
        artists: allSanityArtist {
            edges {
                node {
                    ...Artist
                }
            }
        }
    }
`
export default (props) => {
  console.log(props.data)
  if(!props.data.studio) {
    return <div>No Studio (reload page)</div>
  }
  const {studio} = props.data
  let data = props.data
  data.page = {
    title: studio.name,
  }
  return (
      <PageTemplate {...props} siteContext={siteContext}>
        <Layout siteContext={siteContext} data={data}>
          <CoverSection covers={studio.coverSection.covers}/>
          <BlockContentSection raw={{blocks: studio._rawStatement}}/>
          <ArtistsSection artists={studio.artists} photo={studio.photo}/>
          <BlockContentSection raw={{blocks: studio._rawDescription}}/>
          <NewsSection raw={studio._rawNewsSection}/>
        </Layout>
      </PageTemplate>
  )
}
