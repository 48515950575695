import React from 'react'
import styled from '@emotion/styled'
import {Row, Col} from '@shockventures/gatsby-theme-common/src/components/Grid'
import Img from 'gatsby-image'
import {Link} from "gatsby"
import {artistSlugify} from "../utils"
import {useSanity} from "@shockventures/gatsby-theme-common";
import {useLocale} from "@shockventures/gatsby-theme-common";
import PageSection from "@shockventures/gatsby-theme-common/src/components/PageSection"

const List = styled.section`
  margin: 0 15px 24px 15px;
  [class*="col-"]:not(:last-child){
    ${props => props.theme.mq({
  marginBottom: ['24px', '24px', '0', '0'],
})};
  }
`
const StyledCol = styled(Col)`
  position: relative;
  &::before{
    content: "";
    display: block;
    padding-top: 100%;  /* initial ratio of 1:1*/
  }
  a {
    display: inline-block;
  }
`
const Content = styled(Col)`
    font-family: ${props => props.theme.bodyFont};
    //font-size: 2rem;
    font-weight: 400;
    line-height: 30px;
    text-align: center;
`
const ArtistInner = styled.div`
  position: absolute;
  top: 0;
  right: 12px;
  bottom: 0;
  left: 12px;
`
const Overlay = styled.div`
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  //background-color: rgba(255,255,255,0.72);
  background-color: rgba(0,0,0,0.40);
`
const Title = styled.h3`
    margin: 0;
    padding: 8px 0;
    text-align: center;
    text-transform: uppercase;
    color: white;
`
const StyledImg = styled(Img)`
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
`

const Artist = ({artist}) => {
  const locale = useLocale()
  const getFluidGatsbyImage = useSanity().getFluidGatsbyImage
  return (
      <Link to={artistSlugify(artist, locale.locale)}>
        <ArtistInner>
          <StyledImg style={{position: 'absolute'}}
                     fluid={getFluidGatsbyImage(artist.profileImage.asset._id, {maxWidth: 800})}/>
          <Overlay><Title>{artist.name}</Title></Overlay>
        </ArtistInner>
      </Link>
  )
}


export default ({artists, photo}) => {
  const getFluidGatsbyImage = useSanity().getFluidGatsbyImage;
  return (
      <PageSection type={'artists'}>
        <List>
          <Row>
            <StyledCol xs={12} sm={4}>
              <Artist artist={artists[0].artist}/>
            </StyledCol>
            <Content xs={12} sm={4}>
              <StyledImg fluid={getFluidGatsbyImage(photo.asset._id, {maxWidth: 800})}/>
            </Content>
            <StyledCol xs={12} sm={4}>
              <Artist artist={artists[1].artist}/>
            </StyledCol>
          </Row>
        </List>
      </PageSection>
  )
}